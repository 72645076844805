import React from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonial = () => {
  return (
    <>
      <section
        id='testimonial'
        className='section bg-gray'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h5
                className='sm-head wow fadeInUp'
                data-wow-delay='0.1s'
              >
                Our Clients
              </h5>
              <h2
                className='wow fadeInUp'
                data-wow-delay='0.3s'
              >
                See what my <span className='p-color'>happy clients</span> say
                about me
              </h2>
            </div>

            <div class='row align-items-md-center'>
              <div class='col-lg-12 col-md-12 col-sm-12'>
                <div
                  id='carouselExampleCaptions'
                  class='carousel slide'
                  data-bs-ride='carousel'
                >
                  {/* <!-- Wrapper for slides --> */}
                  <div class='carousel-inner'>
                    <div class='carousel-item active'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#ffb600'
                            viewBox='0 0 448 512'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Good work. got it right on first attempt! Needed a
                            menu re-designed with less clutter, and Muhammad did
                            it in no time. Good job!
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#ffb600'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row'>
                          <div class='col-sm-2 pt-3'>
                            <img
                              src='./clientsPic/autur.webp'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='saf'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-lg-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Ataur</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>CEO Urban Life Magazine (UK)</span>
                              {/* <span>CodeHim</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#ffb600'
                            viewBox='0 0 448 512'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            {" "}
                            Very satisfied with the result and easy to work with
                            this seller. Will definitely contact the seller
                            again for future projects.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#ffb600'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row'>
                          <div class='col-sm-2 pt-4'>
                            <img
                              src='./clientsPic/c1.jpg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='ssf'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-lg-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Juan Florian</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>Canada</span>

                              {/* <span>Vaxa digital</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#ffb600'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Shaharyar is very accommodating! A true visionary
                            who’s able to put art to your thoughts and make them
                            come to life.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#ffb600'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row text-lg-start'>
                          <div class='col-sm-2 pt-4'>
                            <img
                              src='./clientsPic/c2.jpg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='ssf'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-md-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Les Stevens </span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>US</span>

                              {/* <span>Ca</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#ffb600'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Great to work with. Finished before the due date.
                            Easy to work with on revisions. Just a great
                            experience.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#ffb600'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row text-lg-start'>
                          <div class='col-sm-2 pt-4 align-items-center'>
                            <img
                              src='./clientsPic/c3.jpg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='cilent'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-md-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Jay Wolcott</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>UK</span>

                              {/* <span>Ca</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#ffb600'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Easy to work with, beautiful work thanks you.
                            delivered super-fast. we would love to work with you
                            again.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#ffb600'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row text-lg-start'>
                          <div class='col-sm-2 pt-4 align-items-center'>
                            <img
                              src='./clientsPic/c4.jpg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='cilent'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-md-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Briana</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>US</span>

                              {/* <span>Ca</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#ffb600'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Amazing work. {":)"} He did all the changes I wanted
                            and the resultant was over my expectations. Good
                            communication. Fast response
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#ffb600'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row text-lg-start'>
                          <div class='col-sm-2 pt-4 align-items-center'>
                            <img
                              src='./clientsPic/c5.jpg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='cilent'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-md-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Anneli Westlund</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>Sweden</span>

                              {/* <span>Ca</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='controls push-right'>
                  <a
                    class='btn btn-primary smooth-scroll mt-2 rubberBand m-1'
                    href='#carouselExampleCaptions'
                    data-bs-slide='prev'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='2em'
                      viewBox='0 0 320 512'
                      fill='#ffffff'
                    >
                      {" "}
                      <path d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z' />
                    </svg>
                  </a>
                  <a
                    class='btn btn-primary smooth-scroll mt-2 rubberBand m-1'
                    href='#carouselExampleCaptions'
                    data-bs-slide='next'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='2em'
                      fill='#ffffff'
                      viewBox='0 0 320 512'
                    >
                      <path d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z' />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
