import { TypeAnimation } from "react-type-animation";
const Header = () => {
  return (
    <section
      id='home'
      className='header'
    >
      <div className='middle-content'>
        <div class='container wow zoomInDown'>
          <h1>
            <small className='text-uppercase'>
              Welcome to my <span class='p-color'>World </span>
            </small>
            <span class='p-color'>Shaharyar Saleem</span>
          </h1>
          <p className='wow rubberBand'>
            I work as a freelance
            <span class='p-color'>
              <TypeAnimation
                sequence={[
                  " Graphic Designer",
                  1000,
                  " Video Editor",
                  1000,
                  " UI/UX Designer",
                  1000,
                  " Pro Designer",
                  500,
                ]}
                speed={25}
                repeat={Infinity}
              />
            </span>
          </p>
          <a
            href='#contact-us'
            class='btn btn-primary smooth-scroll mt-2 rubberBand btn-header'
            data-wow-delay='2.2s'
          >
            Hire Me
          </a>
        </div>
      </div>
      {/* <figure>
        <picture>
          <source
            media='(max-width: 480px)'
            srcset='/header-xsm-min.png'
            data-src='/header-xsm-min.png'
          />
          <source
            media='(max-width: 1024px)'
            srcset='/header-md-min.png'
            data-src='/header-md-min.png'
          />
          <source
            media='(min-width: 1025px)'
            srcset='https://res.cloudinary.com/www-ahsanjutt-com/image/upload/v1700922730/shari/mlnikxvutmltcw6m73jx.png'
            data-src='https://res.cloudinary.com/www-ahsanjutt-com/image/upload/v1700922730/shari/mlnikxvutmltcw6m73jx.png'
          />
          <img
            src='https://res.cloudinary.com/www-ahsanjutt-com/image/upload/v1700922730/shari/mlnikxvutmltcw6m73jx.png'
            data-src-base='/'
            data-src='{xs:/header-xsm-min.png,md:/header-md-min.png,xl:https://res.cloudinary.com/www-ahsanjutt-com/image/upload/v1700922730/shari/mlnikxvutmltcw6m73jx.png}'
            alt=''
          />
        </picture>
      </figure> */}
    </section>
  );
};

export default Header;
