const Certificate = () => {
  return (
    <section className='section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/1.png'
              alt='filmora'
            />
          </div>

          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/2.png'
              alt='filmora'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/3.png'
              alt='filmora'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/4.png'
              alt='filmora'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/5.png'
              alt='filmora'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/6.png'
              alt='filmora'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certificate;
