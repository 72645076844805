const WhyChose = () => {
  return (
    <>
      <section
        id='whyChose'
        className='section bg-gray'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h5
                className='sm-head wow fadeInUp'
                data-wow-delay='0.1s'
              >
                Why Chose
              </h5>
              <h2
                className='wow fadeInUp'
                data-wow-delay='0.3s'
              >
                I'm smart,
                <span class='p-color'> hard working</span>, easy to talk to, and
                I love a challenged project
              </h2>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div
                className='s-card wow fadeInUp'
                data-wow-delay='0.5s'
              >
                <h4>
                  <span className='p-color'>Creative </span> Brilliance
                </h4>
                <h1>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='0.8em'
                    fill='#fca311'
                    viewBox='0 0 512 512'
                  >
                    <path d='M48 256C48 141.1 141.1 48 256 48c63.1 0 119.6 28.1 157.8 72.5c8.6 10.1 23.8 11.2 33.8 2.6s11.2-23.8 2.6-33.8C403.3 34.6 333.7 0 256 0C114.6 0 0 114.6 0 256v40c0 13.3 10.7 24 24 24s24-10.7 24-24V256zm458.5-52.9c-2.7-13-15.5-21.3-28.4-18.5s-21.3 15.5-18.5 28.4c2.9 13.9 4.5 28.3 4.5 43.1v40c0 13.3 10.7 24 24 24s24-10.7 24-24V256c0-18.1-1.9-35.8-5.5-52.9zM256 80c-19 0-37.4 3-54.5 8.6c-15.2 5-18.7 23.7-8.3 35.9c7.1 8.3 18.8 10.8 29.4 7.9c10.6-2.9 21.8-4.4 33.4-4.4c70.7 0 128 57.3 128 128v24.9c0 25.2-1.5 50.3-4.4 75.3c-1.7 14.6 9.4 27.8 24.2 27.8c11.8 0 21.9-8.6 23.3-20.3c3.3-27.4 5-55 5-82.7V256c0-97.2-78.8-176-176-176zM150.7 148.7c-9.1-10.6-25.3-11.4-33.9-.4C93.7 178 80 215.4 80 256v24.9c0 24.2-2.6 48.4-7.8 71.9C68.8 368.4 80.1 384 96.1 384c10.5 0 19.9-7 22.2-17.3c6.4-28.1 9.7-56.8 9.7-85.8V256c0-27.2 8.5-52.4 22.9-73.1c7.2-10.4 8-24.6-.2-34.2zM256 160c-53 0-96 43-96 96v24.9c0 35.9-4.6 71.5-13.8 106.1c-3.8 14.3 6.7 29 21.5 29c9.5 0 17.9-6.2 20.4-15.4c10.5-39 15.9-79.2 15.9-119.7V256c0-28.7 23.3-52 52-52s52 23.3 52 52v24.9c0 36.3-3.5 72.4-10.4 107.9c-2.7 13.9 7.7 27.2 21.8 27.2c10.2 0 19-7 21-17c7.7-38.8 11.6-78.3 11.6-118.1V256c0-53-43-96-96-96zm24 96c0-13.3-10.7-24-24-24s-24 10.7-24 24v24.9c0 59.9-11 119.3-32.5 175.2l-5.9 15.3c-4.8 12.4 1.4 26.3 13.8 31s26.3-1.4 31-13.8l5.9-15.3C267.9 411.9 280 346.7 280 280.9V256z' />
                  </svg>
                </h1>
                <p className='justify-p'>
                  My designs showcase an innovative blend of intelligence and
                  artistic prowess, ensuring each project is a testament to our
                  unique and strategic approach.
                </p>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div
                className='s-card wow fadeInUp'
                data-wow-delay='0.7s'
              >
                <h4>
                  <span className='p-color'>Work </span> Ethic{" "}
                </h4>
                <h1>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='0.8em'
                    viewBox='0 0 512 512'
                    fill='#fca311'
                  >
                    <path d='M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z' />
                  </svg>
                </h1>
                <p className='justify-p'>
                  I tirelessly pursue design excellence, delivering meticulously
                  crafted visuals that not only meet but exceed expectations,
                  setting a standard of unwavering.
                </p>
              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div
                className='s-card wow fadeInUp'
                data-wow-delay='0.9s'
              >
                <h1>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='0.8em'
                    viewBox='0 0 576 512'
                    fill='#fca311'
                  >
                    <path d='M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z' />
                  </svg>
                </h1>
                <h4>
                  <span className='p-color'>Open </span> Communication{" "}
                </h4>

                <p className='justify-p'>
                  Approachable and communication-focused, I make client
                  collaboration effortless, ensuring ideas are not just heard
                  but vividly translated into impactful designs.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div
                className='s-card wow fadeInUp'
                data-wow-delay='0.9s'
              >
                <h4>
                  <span className='p-color'>Strategic </span> Thinking{" "}
                </h4>
                <h1>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='0.8em'
                    viewBox='0 0 384 512'
                    fill='#fca311'
                  >
                    <path d='M16 64C16 28.7 44.7 0 80 0H304c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H80c-35.3 0-64-28.7-64-64V64zM224 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM304 64H80V384H304V64z' />
                  </svg>
                </h1>
                <p className='justify-p'>
                  Remaining adaptable in a dynamic industry, I stay ahead of
                  design trends, offering designs that resonate with
                  contemporary audiences.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div
                className='s-card wow fadeInUp'
                data-wow-delay='0.9s'
              >
                <h4>
                  <span className='p-color'>Deadline </span>Commitment{" "}
                </h4>
                <h1>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='0.8em'
                    viewBox='0 0 512 512'
                    fill='#fca311'
                  >
                    <path d='M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z' />
                  </svg>
                </h1>
                <p className='justify-p'>
                  With a keen understanding of project timelines, we prioritize
                  meeting deadlines without compromising the quality of our
                  work.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <div
                className='s-card wow fadeInUp'
                data-wow-delay='0.9s'
              >
                <h4>
                  <span className='p-color'>Passion </span> for Impact{" "}
                </h4>
                <h1>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='0.8em'
                    viewBox='0 0 512 512'
                    fill='#fca311'
                  >
                    <path d='M385.1 419.1C349.7 447.2 304.8 464 256 464s-93.7-16.8-129.1-44.9l80.4-80.4c14.3 8.4 31 13.3 48.8 13.3s34.5-4.8 48.8-13.3l80.4 80.4zm68.1 .2C489.9 374.9 512 318.1 512 256s-22.1-118.9-58.8-163.3L465 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L419.3 58.8C374.9 22.1 318.1 0 256 0S137.1 22.1 92.7 58.8L81 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L58.8 92.7C22.1 137.1 0 193.9 0 256s22.1 118.9 58.8 163.3L47 431c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l11.8-11.8C137.1 489.9 193.9 512 256 512s118.9-22.1 163.3-58.8L431 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-11.8-11.8zm-34.1-34.1l-80.4-80.4c8.4-14.3 13.3-31 13.3-48.8s-4.8-34.5-13.3-48.8l80.4-80.4C447.2 162.3 464 207.2 464 256s-16.8 93.7-44.9 129.1zM385.1 92.9l-80.4 80.4c-14.3-8.4-31-13.3-48.8-13.3s-34.5 4.8-48.8 13.3L126.9 92.9C162.3 64.8 207.2 48 256 48s93.7 16.8 129.1 44.9zM173.3 304.8L92.9 385.1C64.8 349.7 48 304.8 48 256s16.8-93.7 44.9-129.1l80.4 80.4c-8.4 14.3-13.3 31-13.3 48.8s4.8 34.5 13.3 48.8zM208 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z' />
                  </svg>
                </h1>
                <p className='justify-p'>
                  Beyond aesthetics, our ultimate goal is to create designs that
                  leave a lasting impact, resonating with audiences and
                  achieving the intended objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Promo Section */}
      <section class='section bg-promo text-center'>
        <div class='container wow zoomInDown'>
          <p class='lead text-center'>I am Available for Freelancer.</p>
          <h2 class='text-10 mb-4'>Start a project with Me today!</h2>
          <a
            href='#contact-us'
            class='btn btn-primary smooth-scroll mt-2 rubberBand'
            data-wow-delay='2.2s'
          >
            Hire Me
          </a>{" "}
        </div>
      </section>
    </>
  );
};

export default WhyChose;
