import { useState, useCallback, useEffect } from "react";
import PortfolioCarusel from "./portfolio-carusel";
import ImageViewer from "react-simple-image-viewer";

const Portfolio = () => {
  const [activeLink, setActiveLink] = useState("Recent Work");
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  const links = [
    "Recent Work",
    "Amazon Listing Images",
    "Book Cover",
    "Restaurant Menu",
    "Flyer & Poster",
    "Brochure & Leaflet",
    "Social Media Design",
    "Banner & Cover",
    "UI/UX",
    "Packaging & Lable",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    console.log(index);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const portfolioImages = [
    // Amazon Listing Images
    {
      src: "../website/amazonListing/1.jpg",
      projectName: "Protein Powder",
      title: "Amazon Listing Images",
      isAll: true,
    },
    {
      src: "../website/amazonListing/2.jpg",
      projectName: "Protein Powder",
      title: "Amazon Listing Images",
    },
    {
      src: "../website/amazonListing/3.jpg",
      projectName: "Face Serum",
      title: "Amazon Listing Images",
    },
    {
      src: "../website/amazonListing/4.jpg",
      projectName: "Face Serum",
      title: "Amazon Listing Images",
    },

    {
      src: "../website/amazonListing/5.jpg",
      projectName: "Water Bottles",
      title: "Amazon Listing Images",
    },

    {
      src: "../website/amazonListing/6.jpg",
      projectName: "Water Bottles",
      title: "Amazon Listing Images",
    },

    // Book Cover
    {
      src: "../website/bookCover/1.jpg",
      projectName: "MindFullness",
      title: "Book Cover",
      isAll: true,
    },
    {
      src: "../website/bookCover/2.jpg",
      projectName: "Energy Management",
      title: "Book Cover",
    },
    {
      src: "../website/bookCover/3.jpg",
      projectName: "Under The Trees",
      title: "Book Cover",
    },
    {
      src: "../website/bookCover/4.jpg",
      projectName: "Romantic Partners",
      title: "Book Cover",
    },

    {
      src: "../website/bookCover/5.jpg",
      projectName: "Hidden Wonder",
      title: "Book Cover",
    },

    {
      src: "../website/bookCover/6.jpg",
      projectName: "Healthy Food",
      title: "Book Cover",
    },

    // Restaurant Menu
    {
      src: "../website/restaurantMenu/1.jpg",
      projectName: "Janan Cafe",
      title: "Restaurant Menu",
      isAll: true,
    },
    {
      src: "../website/restaurantMenu/2.jpg",
      projectName: "Bifold Menu",
      title: "Restaurant Menu",
    },
    {
      src: "../website/restaurantMenu/3.jpg",
      projectName: "Special Cafe",
      title: "Restaurant Menu",
    },
    {
      src: "../website/restaurantMenu/4.jpg",
      projectName: "Amarat ul Shawarma",
      title: "Restaurant Menu",
    },

    {
      src: "../website/restaurantMenu/5.jpg",
      projectName: "Hot Deals",
      title: "Restaurant Menu",
    },

    {
      src: "../website/restaurantMenu/6.jpg",
      projectName: "The Bristol Spice Factory",
      title: "Restaurant Menu",
    },

    {
      src: "../website/restaurantMenu/7.jpg",
      projectName: "Janan",
      title: "Restaurant Menu",
    },
    {
      src: "../website/restaurantMenu/8.jpg",
      projectName: "Burger Shop",
      title: "Restaurant Menu",
    },

    //  Flyer & Poster
    {
      src: "../website/flyerPoster/1.jpg",
      projectName: "Group",
      title: "Flyer & Poster",
      isAll: true,
    },
    {
      src: "../website/flyerPoster/2.jpg",
      projectName: "Creative Agency",
      title: "Flyer & Poster",
    },
    {
      src: "../website/flyerPoster/3.jpg",
      projectName: "Music Party",
      title: "Flyer & Poster",
    },
    {
      src: "../website/flyerPoster/4.jpg",
      projectName: "Christmas",
      title: "Flyer & Poster",
    },

    {
      src: "../website/flyerPoster/5.jpg",
      projectName: "Group",
      title: "Flyer & Poster",
    },

    {
      src: "../website/flyerPoster/6.jpg",
      projectName: "Green Tea",
      title: "Flyer & Poster",
    },

    {
      src: "../website/flyerPoster/7.jpg",
      projectName: "Christmas",
      title: "Flyer & Poster",
    },
    {
      src: "../website/flyerPoster/8.jpg",
      projectName: "Medical",
      title: "Flyer & Poster",
    },
    {
      src: "../website/flyerPoster/9.jpg",
      projectName: "Fasion",
      title: "Flyer & Poster",
    },
    {
      src: "../website/flyerPoster/10.jpg",
      projectName: "Food",
      title: "Flyer & Poster",
    },
    {
      src: "../website/flyerPoster/11.jpg",
      projectName: "Travel",
      title: "Flyer & Poster",
    },
    // Brochure & Leaflet
    {
      src: "../website/brochureLeaflet/1.jpg",
      projectName: "Construction",
      title: "Brochure & Leaflet",
      isAll: true,
    },
    {
      src: "../website/brochureLeaflet/2.jpg",
      projectName: "Real Estate",
      title: "Brochure & Leaflet",
    },
    {
      src: "../website/brochureLeaflet/3.jpg",
      projectName: "Medical",
      title: "Brochure & Leaflet",
    },
    {
      src: "../website/brochureLeaflet/4.jpg",
      projectName: "SPA",
      title: "Brochure & Leaflet",
    },

    {
      src: "../website/brochureLeaflet/5.jpg",
      projectName: "Women Collection",
      title: "Brochure & Leaflet",
    },

    {
      src: "../website/brochureLeaflet/6.jpg",
      projectName: "Women Collection",
      title: "Brochure & Leaflet",
    },
    // Social Media Design
    {
      src: "../website/socialMediaDesign/1.jpg",
      projectName: "Group",
      title: "Social Media Design",
      isAll: true,
    },
    {
      src: "../website/socialMediaDesign/2.jpg",
      projectName: "Shoes",
      title: "Social Media Design",
    },
    {
      src: "../website/socialMediaDesign/3.jpg",
      projectName: "Rent a Car",
      title: "Social Media Design",
    },
    {
      src: "../website/socialMediaDesign/4.jpg",
      projectName: "Gaming Chair",
      title: "Social Media Design",
    },
    {
      src: "../website/socialMediaDesign/5.jpg",
      projectName: "Burger",
      title: "Social Media Design",
    },

    {
      src: "../website/socialMediaDesign/6.jpg",
      projectName: "Pizza",
      title: "Social Media Design",
    },

    {
      src: "../website/socialMediaDesign/7.jpg",
      projectName: "Burger",
      title: "Social Media Design",
    },

    {
      src: "../website/socialMediaDesign/8.jpg",
      projectName: "Trimmer",
      title: "Social Media Design",
    },

    {
      src: "../website/socialMediaDesign/9.jpg",
      projectName: "Smart Watch",
      title: "Social Media Design",
    },

    {
      src: "../website/socialMediaDesign/10.jpg",
      projectName: "Graphic Designer",
      title: "Social Media Design",
    },

    {
      src: "../website/socialMediaDesign/11.jpg",
      projectName: "Food",
      title: "Social Media Design",
    },
    {
      src: "../website/socialMediaDesign/12.jpg",
      projectName: "Headphone",
      title: "Social Media Design",
    },

    // Banner & Cover
    {
      src: "../website/bannerCover/1.jpg",
      projectName: "Shoes",
      title: "Banner & Cover",
      isAll: true,
    },
    {
      src: "../website/bannerCover/2.jpg",
      projectName: "Furniture",
      title: "Banner & Cover",
    },
    {
      src: "../website/bannerCover/3.jpg",
      projectName: "Oil",
      title: "Banner & Cover",
    },
    {
      src: "../website/bannerCover/4.jpg",
      projectName: "Shoes",
      title: "Banner & Cover",
    },
    {
      src: "../website/bannerCover/5.jpg",
      projectName: "Travel",
      title: "Banner & Cover",
    },

    {
      src: "../website/bannerCover/6.jpg",
      projectName: "Fasion",
      title: "Banner & Cover",
    },
    {
      src: "../website/bannerCover/7.jpg",
      projectName: "Headphone",
      title: "Banner & Cover",
    },
    {
      src: "../website/bannerCover/8.jpg",
      projectName: "Shoes",
      title: "Banner & Cover",
    },

    //  UI/UX Ad
    {
      src: "../website/ui/1.jpg",
      projectName: "Mobile App",
      title: "UI/UX",
      isAll: true,
    },
    {
      src: "../website/ui/2.jpg",
      projectName: "Web Landing Page",
      title: "UI/UX",
    },
    {
      src: "../website/ui/3.jpg",
      projectName: "Mobile App",
      title: "UI/UX",
    },

    {
      src: "../website/ui/4.jpg",
      projectName: "Web Landing Page",
      title: "UI/UX",
    },

    //  Packaging & Lable
    {
      src: "../website/lable/1.jpg",
      projectName: "Bulb Box",
      title: "Packaging & Lable",
      isAll: true,
    },
    {
      src: "../website/lable/2.jpg",
      projectName: "Pancil Box",
      title: "Packaging & Lable",
    },
    {
      src: "../website/lable/3.jpg",
      projectName: "Bulb Box",
      title: "Packaging & Lable",
    },
  ];

  useEffect(() => {
    const imgs = portfolioImages.filter((x) => x.isAll).map((m) => m.src);
    setImages(imgs);
  }, []);
  const onGallaryNavClick = (value) => {
    setActiveLink(value);
    setCurrentImage(0);
    const imags = portfolioImages
      .filter((x) => x.title === value)
      .map((m) => m.src);
    setImages(imags);
  };
  return (
    <section
      id='portfolio'
      className='section'
    >
      {/* <!-- START Portfolio Modal --> */}
      <div
        class='modal fade '
        id='portfolioModal'
        tabindex='-1'
        aria-labelledby='portfolioModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-dialog-centered modal-xl'>
          <div class='modal-content bg-gray'>
            <div class='modal-header'>
              <h5
                class='modal-title'
                id='portfolioModalLabel'
              >
                Project title
              </h5>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <div className='row'>
                <div className='col-sm-6'>
                  <PortfolioCarusel />
                </div>

                <div className='col-sm-6'>
                  <h3> Project Info:</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Earum veniam dicta vitae ullam praesentium error sapiente
                    optio, eveniet voluptates ipsam molestias quisquam magni
                    iusto soluta expedita, blanditiis voluptatibus possimus iste
                    dignissimos iure libero.
                  </p>

                  <h3> Project Details:</h3>
                  <div className='pt-3'>
                    <span className='small-heading'>Client: </span>Mauri
                    Lindberg
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>Industry: </span>Books &
                    Reference
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>Technologies: </span>iOS,
                    Android, PHP, Java
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>Date: </span>Sep 29, 2019
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>URL: </span>
                    <a
                      className=''
                      href='www.example.com'
                    >
                      www.example.com
                    </a>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- END Portfolio Modal --> */}
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Portfolio
            </h5>
            <h2
              className='wow fadeInUp'
              data-wow-delay='0.2s'
            >
              I help you build <span className='p-color'>brand</span> for your
              business
              {/* at an affordable price. Thousands of clients have procured exceptional results while working with Me. */}
            </h2>
          </div>
        </div>

        <ul className='nav nav-pills text-uppercase justify-content-center border-bottom-0 mb-5 fadeInUp'>
          {links.map((x) => (
            <li className='nav-item'>
              {" "}
              <a
                class={`nav-link ${activeLink == x ? "active-port-link" : ""}`}
                onClick={() => onGallaryNavClick(x)}
              >
                {x}
              </a>
            </li>
          ))}
        </ul>

        <div className='row'>
          <div className='col-lg-12'>
            <div
              className='row wow '
              data-wow-delay='0.3s'
            >
              {portfolioImages.map((x, i) => (
                <>
                  {" "}
                  {activeLink === x.title ||
                  (activeLink === "Recent Work" && x?.isAll) ? (
                    <div
                      key={i + 1}
                      className='col-lg-4 p-0 p-2 zoomIn image-portfolio-div'
                      data-wow-delay={`${0.1 + i / 10}s`}
                    >
                      <img
                        className='img-fluid shadow-md rounded d-inline-block'
                        src={x.src}
                        onClick={() => openImageViewer(i)}
                        title={x.title}
                        alt={x.title}
                      />

                      <div class='portfolio-overlay m-2'>
                        {" "}
                        <a
                          class='popup-ajax stretched-link'
                          // data-bs-toggle='modal'
                          // data-bs-target='#portfolioModal'

                          onClick={() => openImageViewer(i)}
                        ></a>
                        <div class='portfolio-overlay-details'>
                          <p class='text-white text-6'>
                            <i class='fas fa-plus'></i>
                          </p>
                          <h5 class='text-white fw-400'>{x?.projectName}</h5>
                          <span class='text-light'>{x.title}</span>{" "}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* {isViewerOpen && (
        <ImageViewer
          style={{ zIndex: "1200" }}
          src={images}
          id='image-viewer'
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )} */}
    </section>
  );
};

export default Portfolio;
