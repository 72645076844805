const Services = () => {
  return (
    <section
      id='services'
      className='section bg-gray'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Services
            </h5>
            <h2
              className='wow fadeInUp'
              data-wow-delay='0.3s'
            >
              I offer these <span class='p-color'>services </span> for your
              Graphic Design project
            </h2>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.5s'
            >
              <h4>
                <span className='p-color'>Amazon</span> Listing Images
              </h4>
              <h1>01</h1>
              <p>
                Elevate your product presence on Amazon with captivating visuals
                that enhance customer engagement and drive sales, ensuring your
                products stand out in the competitive marketplace.
              </p>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Book</span> Cover{" "}
              </h4>
              <h1>02</h1>
              <p>
                Immerse readers in your narrative from the first glance with
                meticulously crafted book covers that convey the essence of your
                story, leaving a lasting impression on potential readers.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Restaurant </span>Menu{" "}
              </h4>
              <h1>03</h1>
              <p>
                Enhance the dining experience for your customers through
                visually appealing menu designs that showcase your cuisine,
                creating anticipation and setting the tone for a delightful
                meal.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Flyer</span> & Poster{" "}
              </h4>
              <h1>04</h1>
              <p>
                Flyer Make your events or promotions unforgettable with
                impactful flyer and poster designs, capturing attention and
                conveying key information in a visually compelling way.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Brochure</span> & Leaflet{" "}
              </h4>
              <h1>05</h1>
              <p>
                Tell your brand's story with informative and visually appealing
                brochures and leaflets, creating a tangible and engaging piece
                that communicates your message effectively.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.7s'
            >
              <h4>
                <span className='p-color'>Social </span> Media Design{" "}
              </h4>
              <h1>06</h1>
              <p>
                Boost your online presence with visually stunning and shareable
                social media posts, connecting with your audience and conveying
                your brand's personality across digital platforms.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Banner</span> & Cover{" "}
              </h4>
              <h1>07</h1>
              <p>
                Reinforce your brand identity with banner and cover designs,
                creating a cohesive visual identity across various platforms and
                leaving a lasting impression on your audience.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>UI/UX </span>Design{" "}
              </h4>
              <h1>08</h1>
              <p>
                Enhance user experiences with intuitive and visually pleasing
                UI/UX designs, ensuring a seamless and enjoyable interaction
                with your digital products or platforms.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Packaging </span>& Label{" "}
              </h4>
              <h1>09</h1>
              <p>
                Wrap your products in eye-catching packaging and label designs
                that not only protect but also communicate your brand's
                uniqueness, making a strong visual impact on the retail shelf.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
