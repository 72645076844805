const PortfolioCarusel = () => {
  return (
    <>
      <div
        id='carouselExampleControls'
        class='carousel slide'
        data-bs-ride='carousel'
      >
        <div class='carousel-inner'>
          <div class='carousel-item active'>
            <img
              src='./project-1.jpg'
              class='d-block w-100'
              alt='s'
            />
          </div>
          <div class='carousel-item'>
            <img
              src='./project-1.jpg'
              class='d-block w-100'
              alt='s'
            />
          </div>
          <div class='carousel-item'>
            <img
              src='./project-1.jpg'
              class='d-block w-100'
              alt='s'
            />
          </div>
        </div>
        <button
          class='carousel-control-prev'
          type='button'
          data-bs-target='#carouselExampleControls'
          data-bs-slide='prev'
        >
          <span
            class='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span class='visually-hidden'>Previous</span>
        </button>
        <button
          class='carousel-control-next'
          type='button'
          data-bs-target='#carouselExampleControls'
          data-bs-slide='next'
        >
          <span
            class='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span class='visually-hidden'>Next</span>
        </button>
      </div>
    </>
  );
};

export default PortfolioCarusel;
