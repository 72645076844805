import { useEffect, useState } from "react";

import customJs from "../assets/js/custom";
const Navbar = () => {
  const [activeLink, setActiveLink] = useState("#home");

  useEffect(() => {
    customJs();
    const sections = document.querySelectorAll("section");
    const navLi = document.querySelectorAll(".navbar-nav li");
    window.onscroll = () => {
      var current = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;

        // eslint-disable-next-line no-restricted-globals
        if (pageYOffset >= sectionTop - 160) {
          current = section.getAttribute("id");
        }
      });
      navLi.forEach((li) => {
        li.classList.remove("active");
        const anchor = li.querySelector("a");
        const hrefValue = anchor.getAttribute("href");

        console.log(
          "hrefValue.includes(current) => ",
          hrefValue.includes(current)
        );

        console.log("current => ", current);
        if (hrefValue.includes(current)) {
          li.classList.add("active");
        }
      });
    };
  }, []);
  const handleNavLinkClick = (targetSection) => {
    setActiveLink(targetSection);
  };

  return (
    <nav className='navbar navbar-expand-lg'>
      <a
        className='navbar-brand wow fadeInUp text-uppercase'
        data-wow-delay='0.1s'
        href='/#home'
      >
        Shaharyar.
      </a>
      <button
        className='navbar-toggler wow fadeInUp'
        data-wow-delay='0.2s'
        type='button'
        data-bs-toggle='collapse'
        data-bs-target='#navbarNavAltMarkup'
        aria-controls='navbarNavAltMarkup'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-list'
          viewBox='0 0 16 16'
        >
          <path
            fill-rule='evenodd'
            d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
          />
        </svg>
      </button>

      <div
        className='collapse navbar-collapse wow fadeInUp'
        data-wow-delay='0.3s'
        id='navbarNavAltMarkup'
      >
        <ul className='navbar-nav'>
          <li className={`nav-item ${activeLink === "#home" ? "active" : ""}`}>
            <a
              className='nav-link'
              href='#home'
              onClick={() => handleNavLinkClick("#home")}
            >
              Home
            </a>
          </li>
          <li
            className={`nav-item ${activeLink === "#aboutMe" ? "active" : ""}`}
          >
            <a
              className='nav-link'
              href='#aboutMe'
              onClick={() => handleNavLinkClick("#aboutMe")}
            >
              About Me
            </a>
          </li>
          <li
            className={`nav-item ${activeLink === "#services" ? "active" : ""}`}
          >
            <a
              className='nav-link'
              href='#services'
              onClick={() => handleNavLinkClick("#services")}
            >
              Services
            </a>
          </li>
          <li
            className={`nav-item ${
              activeLink === "#portfolio" ? "active" : ""
            }`}
          >
            <a
              className='nav-link'
              href='#portfolio'
              onClick={() => handleNavLinkClick("#portfolio")}
            >
              Portfolio
            </a>
          </li>
          <li
            className={`nav-item ${activeLink === "#whyChose" ? "active" : ""}`}
          >
            <a
              className='nav-link'
              href='#whyChose'
              onClick={() => handleNavLinkClick("#whyChose")}
            >
              Why Chose
            </a>
          </li>
          <li
            className={`nav-item ${
              activeLink === "#experience-reference" ? "active" : ""
            }`}
          >
            <a
              className='nav-link'
              href='#experience-reference'
              onClick={() => handleNavLinkClick("#experience-reference")}
            >
              Experience & Reference
            </a>
          </li>
          {/* testimonial */}
          <li
            className={`nav-item ${
              activeLink === "#testimonial" ? "active" : ""
            }`}
          >
            <a
              className='nav-link'
              href='#testimonial'
              onClick={() => handleNavLinkClick("#testimonial")}
            >
              Our Clients
            </a>
          </li>
          <li
            className={`nav-item ${
              activeLink === "#contact-us" ? "active" : ""
            }`}
          >
            <a
              href='#contact-us'
              className='nav-link'
              onClick={() => handleNavLinkClick("#contact-us")}
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
