const Skills = () => {
  return (
    <>
      <section
        className='col-md-4 wow fadeInUp'
        data-wow-delay='0.1s'
      >
        <div className='exp-progress'>
          <h3 className='text-uppercase'>
            <span>90%</span>Photoshop
          </h3>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              style={{
                width: "90%",
              }}
              aria-valuenow='1'
              aria-valuemin='0'
              aria-valuemax='90'
            ></div>
          </div>
        </div>
      </section>

      <div
        className='col-md-4 wow fadeInUp'
        data-wow-delay='0.2s'
      >
        <div className='exp-progress'>
          <h3 className='text-uppercase'>
            <span>95%</span>Illustrator
          </h3>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              style={{
                width: "95%",
              }}
              aria-valuenow='100'
              aria-valuemin='0'
              aria-valuemax='95'
            ></div>
          </div>
        </div>
      </div>

      <div
        className='col-md-4 wow fadeInUp'
        data-wow-delay='0.3s'
      >
        <div className='exp-progress'>
          <h3 className='text-uppercase'>
            <span>91%</span>InDesign
          </h3>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              style={{ width: "91%" }}
              aria-valuenow='100'
              aria-valuemin='0'
              aria-valuemax='91'
            ></div>
          </div>
        </div>
      </div>

      <div
        className='col-md-4 wow fadeInUp'
        data-wow-delay='0.4s'
      >
        <div className='exp-progress'>
          <h3 className='text-uppercase'>
            <span>85%</span>Figma
          </h3>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              style={{ width: "85%" }}
              aria-valuenow='100'
              aria-valuemin='0'
              aria-valuemax='85'
            ></div>
          </div>
        </div>
      </div>
      <div
        className='col-md-4 wow fadeInUp'
        data-wow-delay='0.5s'
      >
        <div className='exp-progress'>
          <h3 className='text-uppercase'>
            <span>80%</span>Premiere pro
          </h3>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              style={{ width: "80%" }}
              aria-valuenow='100'
              aria-valuemin='0'
              aria-valuemax='80'
            ></div>
          </div>
        </div>
      </div>

      <div
        className='col-md-4 wow fadeInUp'
        data-wow-delay='0.6s'
      >
        <div className='exp-progress'>
          <h3 className='text-uppercase'>
            <span>100%</span>Filmora
          </h3>
          <div className='progress'>
            <div
              className='progress-bar'
              role='progressbar'
              style={{ width: "100%" }}
              aria-valuenow='100'
              aria-valuemin='0'
              aria-valuemax='100'
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
