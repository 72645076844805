import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

const Contact = () => {
  // loading for sending email messages
  const [Loading, SetLoading] = useState(false);

  const contactForm = useRef();

  // React hook forms for our Conatact us form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  // sned email from conatct us form fields
  const onSubmitForm = (data) => {
    // shari
    const shariServiceId = "service_bjas8ej";
    const shariTemplateId = "template_w2cxpge";
    const shariPublicKey = "NQZLKTAFuDi5fmZjS";

    // ahsan
    const ahsanServiceId = "service_wuw720k";
    const ahsanTemplateId = "template_t4bcfpf";
    const ahsanPublicKey = "wtRMT8TlGx2LMGiOT";
    SetLoading(true);
    console.log("Contact us Form: " + contactForm.current);
    emailjs
      .sendForm(
        shariServiceId,
        shariTemplateId,
        contactForm.current,
        shariPublicKey
      )
      .then(
        (result) => {
          console.log(result.text);
          SetLoading(false);
          reset();
        },
        (error) => {
          console.log(error.text);
          SetLoading(false);
        }
      );
  };
  return (
    <section
      id='contact-us'
      className='section section-right bg-gray'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Contact
            </h5>
            <h2
              className='wow fadeInUp'
              data-wow-delay='0.2s'
            >
              Get in <span className='p-color'>touch</span>
            </h2>
          </div>

          <div className='col-lg-4 col-md-12 pb-30'>
            <h3
              className='wow fadeInUp'
              data-wow-delay='0.3s'
            >
              Call Us.
            </h3>
            <p
              className='phone wow fadeInUp'
              data-wow-delay='0.4s'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-telephone'
                viewBox='0 0 16 16'
              >
                <path d='M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z' />
              </svg>{" "}
              +92313-7515057
            </p>

            <p
              className='phone wow fadeInUp'
              data-wow-delay='0.4s'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 448 512'
              >
                <path d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z' />
              </svg>{" "}
              +92313-7515057
            </p>
            <h3
              className='wow fadeInUp'
              data-wow-delay='0.6s'
            >
              Email.
            </h3>
            <p
              className='wow fadeInUp'
              data-wow-delay='0.7s'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-envelope'
                viewBox='0 0 16 16'
              >
                <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z' />
              </svg>{" "}
              shaharyar261@gmail.com
            </p>
            <h3
              className='wow fadeInUp'
              data-wow-delay='0.8s'
            >
              Location.
            </h3>
            <p
              className='wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-geo-alt'
                viewBox='0 0 16 16'
              >
                <path d='M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z' />
                <path d='M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
              </svg>{" "}
              Burewala Pakistan
            </p>
          </div>

          <div className='col-lg-8 col-md-12 pt-md-set'>
            <form
              ref={contactForm}
              key={1}
              onSubmit={handleSubmit(onSubmitForm)}
              autoComplete='off'
            >
              <div className='form'>
                <div className='row'>
                  <div className='col-12'>
                    <h3
                      className='wow fadeInUp'
                      data-wow-delay='0.1s'
                    >
                      Send A Message
                    </h3>
                  </div>
                  <div
                    className='col-md-6 mb-3 wow fadeInUp '
                    data-wow-delay='0.2s'
                  >
                    <label
                      for='Name'
                      id='Name'
                      className={`form-label ${
                        errors.name?.message ? "danger" : ""
                      }`}
                    >
                      Name
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.name?.message ? "error-input" : ""
                      }`}
                      placeholder='Name'
                      id='name'
                      style={{
                        fontSize: "1rem",
                      }}
                      {...register("name", {
                        required: "Name is required.",
                      })}
                    />
                    <div className='danger'>{errors.name?.message}</div>
                  </div>

                  <div
                    className='col-md-6 mb-3 wow fadeInUp'
                    data-wow-delay='0.3s'
                  >
                    <label
                      for='phone'
                      className={`form-label ${
                        errors.phone?.message ? "danger" : ""
                      }`}
                    >
                      Phone
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.phone?.message ? "error-input" : ""
                      }`}
                      id='phone'
                      placeholder='Phone'
                      style={{ "font-size": "1rem" }}
                      {...register("phone", {
                        required: "Valid phone is required.",
                      })}
                    />
                    <div className='danger'>{errors.phone?.message}</div>
                  </div>

                  <div
                    className='col-md-6 mb-3 wow fadeInUp'
                    data-wow-delay='0.4s'
                  >
                    <label
                      for='email'
                      className={`form-label ${
                        errors.email?.message ? "danger" : ""
                      }`}
                    >
                      Email
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.email?.message ? "error-input" : ""
                      }`}
                      id='email'
                      placeholder='Email'
                      style={{ "font-size": "1rem" }}
                      {...register("email", {
                        required: "Valid email is required.",
                        maxLength: {
                          value: 50,
                          message: "Must be max 50 chars",
                        },
                        validate: (value) => {
                          return (
                            [
                              /^[A-Za-z0-9._+\-\']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$/,
                            ].every((pattern) => pattern.test(value)) ||
                            "Cannot special chars, only lower, upper, number"
                          );
                        },
                      })}
                    />
                    <div className='danger'>{errors.email?.message}</div>
                  </div>

                  <div
                    className='col-md-6 mb-3 wow fadeInUp'
                    data-wow-delay='0.5s'
                  >
                    <label
                      for='subject'
                      className={`form-label ${
                        errors.subject?.message ? "danger" : ""
                      }`}
                    >
                      Subject
                    </label>
                    <input
                      type='text'
                      className={`form-control ${
                        errors.subject?.message ? "error-input" : ""
                      }`}
                      id='subject'
                      placeholder='Subject'
                      style={{ "font-size": "1rem" }}
                      {...register("subject", {
                        required: "Subject is required.",
                      })}
                    />
                    <div className='danger'>{errors.subject?.message}</div>
                  </div>

                  <div
                    className='col-md-12 mb-3 wow fadeInUp'
                    data-wow-delay='0.6s'
                  >
                    <label
                      for='message'
                      className={`form-label ${
                        errors.message?.message ? "danger" : ""
                      }`}
                    >
                      Message
                    </label>
                    <textarea
                      className={`form-control ${
                        errors.message?.message ? "error-input" : ""
                      }`}
                      name='message'
                      id='message'
                      rows='5'
                      placeholder='Message'
                      style={{ "font-size": "1rem" }}
                      {...register("message", {
                        required: "Message is required.",
                      })}
                    ></textarea>
                    <div className='danger'>{errors.message?.message}</div>
                  </div>

                  <div
                    className='col-md-12 wow fadeInUp'
                    data-wow-delay='0.7s'
                  >
                    <button
                      type='submit'
                      className='btn btn-primary'
                    >
                      {Loading && (
                        <div
                          className='spinner-border'
                          role='status'
                        >
                          <span className='sr-only'></span>
                        </div>
                      )}

                      {!Loading && "Send A Message"}
                    </button>{" "}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
