import Skills from "./skills";

const Experience = () => {
  return (
    <section
      id='experience-reference'
      className='section'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Experience & Reference
            </h5>
            <h2
              className='wow fadeInUp'
              data-wow-delay='0.2s'
            >
              It is a long <span className='p-color'>fact that a reader</span>{" "}
              will be distracted
            </h2>
          </div>

          <div className='col-lg-12 col-md-12'>
            <div className='resume-box'>
              <ul>
                <li>
                  <div
                    className='icon wow fadeInUp'
                    data-wow-delay='0.1s'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-briefcase'
                      viewBox='0 0 16 16'
                    >
                      <path d='M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z' />
                    </svg>
                  </div>
                  <span
                    className='time open-sans-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.2s'
                  >
                    08/2023 Till Today
                  </span>
                  <h5
                    className='poppins-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.3s'
                  >
                    Full-time Graphic Designer At Maxtrenza
                  </h5>
                  <p
                    className='open-sans-font wow fadeInUp'
                    data-wow-delay='1s'
                  >
                    My primary focus on social media design, I play a crucial
                    role in visually representing the brand and engaging the
                    audience across various platforms.
                  </p>

                  <div
                    className='icon wow fadeInUp'
                    data-wow-delay='0.7s'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='bi bi-briefcase'
                      viewBox='0 0 16 16'
                    >
                      <path d='M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z' />
                    </svg>
                  </div>
                  <span
                    className='time open-sans-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.8s'
                  >
                    08/2021 Till Today
                  </span>
                  <h5
                    className='poppins-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.9s'
                  >
                    Freelance at People Per Hour, Fiverr and upwork
                    <span></span>
                  </h5>
                  <p
                    className='open-sans-font wow fadeInUp'
                    data-wow-delay='1s'
                  >
                    I work as a freelance graphic designer and offering a range
                    of design services to enhance your visual presence. My
                    expertise encompasses social media design, flyer and
                    brochure design, banner ads, UI/UX, poster design, menu
                    design, Amazon listing image design, and packaging and label
                    design. Let's collaborate to bring your ideas to life
                    through creative and impactful visual solutions.{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <Skills />
        </div>
      </div>
    </section>
  );
};

export default Experience;
