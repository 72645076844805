import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faEye,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

const AboutMe = () => {
  return (
    <section
      id='aboutMe'
      className='section'
    >
      {/* <!-- About Modal --> */}
      <div
        class='modal fade '
        id='aboutMoreModal'
        tabindex='-1'
        aria-labelledby='aboutMoreModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-dialog-centered modal-xl'>
          <div class='modal-content bg-gray'>
            <div class='modal-header'>
              <h5
                class='modal-title'
                id='aboutMoreModalLabel'
              >
                More About Me
              </h5>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <div className='row'>
                <div className='col-sm-12'>
                  <p>
                    My expertise lies in crafting custom solutions that
                    effectively communicate brand stories and leave a lasting
                    impression. By seamlessly blending creativity with strategy,
                    I consistently deliver exceptional results that exceed
                    client expectations.
                  </p>
                </div>
                <div className='row'>
                  <div className='col-sm-12 mt-3'>
                    <h4>
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className='primary-color'
                      />{" "}
                      My Mission
                    </h4>
                  </div>
                  <div className='col-sm-12'>
                    <p className='pl-40'>
                      As graphic designers, my mission is to visually articulate
                      ideas, evoke emotions, and elevate brands through
                      compelling and purposeful design, fostering meaningful
                      connections and leaving a lasting impact.
                    </p>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12 mt-1'>
                  <h4>
                    <FontAwesomeIcon
                      icon={faEye}
                      className='primary-color'
                    />{" "}
                    My Vision
                  </h4>
                </div>
                <div className='col-sm-12'>
                  <p className='pl-40'>
                    My vision is to seamlessly merge creativity and
                    functionality, crafting designs that not only captivate the
                    eye but also convey a meaningful narrative, leaving a
                    lasting impression on the viewer's experience.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 mt-2'>
                  <h4 className=''>
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className='primary-color'
                    />{" "}
                    My Goal
                  </h4>
                </div>
                <div className='col-sm-12 pl-2'>
                  <p className='pl-40'>
                    My goal as a graphic designer is to consistently deliver
                    innovative and visually striking designs that not only meet
                    but exceed the expectations of my clients, creating a
                    lasting impact in the realm of visual communication.
                  </p>
                </div>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About Modal --> */}

      <div className='container'>
        <div className='row'>
          <div
            class='col-lg-5 text-center mb-5 mb-lg-0 wow fadeInUp'
            data-wow-delay='0.2s'
          >
            {" "}
            <img
              class='img-fluid shadow-md rounded d-inline-block wow fadeInUp'
              src='./about-me.jpg'
              data-wow-delay='0.1s'
              title='Muhammad Ahsan Hameed'
              alt=''
            />{" "}
          </div>
          <div
            class='col-lg-7 text-center text-lg-start ps-lg-4 wow fadeInUp'
            data-wow-delay='0.2s'
            // style="visibility: visible; animation-delay: 0.2s;"
          >
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              About Me
            </h5>
            <h2
              class='mb-4 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Hello! <span class='p-color fw-600'>I'm Shaharyar Saleem</span>
            </h2>
            <p className='justify-p'>
              As a freelance graphic designer, I bring a unique blend of
              creativity and technical expertise to my work. With a keen eye for
              detail and a passion for visual storytelling, I specialize in
              creating compelling designs that captivate and engage audiences.
              My dedication to delivering high-quality and
              <strong class='fw-600'> innovative solutions </strong>
              has enabled me to build strong relationships with clients across a
              diverse range of industries.
            </p>
            <p className='justify-p'>
              By staying abreast of the latest design trends and technologies, I
              aim to consistently exceed expectations and bring visions to life.
              <strong class='fw-600'> Let's work together</strong> to create to
              transform your ideas into impactful visuals that resonate with
              your audience.
            </p>
            <div class='brands-grid separator-border my-sm-4'>
              <div class='row'>
                <div class='col-sm-4 py-4 py-sm-2'>
                  <div class='featured-box text-center'>
                    <h4 class='text-8 text-light-emphasis mb-0'>
                      <span
                        class='counter'
                        data-from='0'
                        data-to='10'
                      >
                        2
                      </span>
                      +
                    </h4>
                    <p class='mb-0'>Years Experiance</p>
                  </div>
                </div>
                <div class='col-sm-4 py-4 py-sm-2'>
                  <div class='featured-box text-center'>
                    <h4 class='text-8 text-light-emphasis mb-0'>
                      <span
                        class='counter'
                        data-from='0'
                        data-to='350'
                      >
                        50
                      </span>
                      +
                    </h4>
                    <p class='mb-0'>Happy Clients</p>
                  </div>
                </div>
                <div class='col-sm-4 py-4 py-sm-2'>
                  <div class='featured-box text-center'>
                    <h4 class='text-8 text-light-emphasis mb-0'>
                      <span
                        class='counter'
                        data-from='0'
                        data-to='780'
                      >
                        150
                      </span>
                      +
                    </h4>
                    <p class='mb-0'>Projects Done</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <a
              class="btn btn-secondary rounded-pill mt-3"
              data-bs-toggle="modal"
              data-bs-target="#about-us-details"
              href="#"
            >
              About more
            </a>{" "} */}
            <div className='row'>
              <div
                className='col-md-3 wow fadeInUp'
                data-wow-delay='0.7s'
              >
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#aboutMoreModal'
                >
                  About more
                </button>{" "}
              </div>

              <div className='col-md-5 wow fadeInUp pt-10px'>
                <a
                  href='#portfolio'
                  class=' btn-link smooth-scroll px-4'
                >
                  Discover My Work
                  {/* <span class="text-1 ms-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                    </svg>
                  </span> */}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
